<template>
<div id="">
  <v-layout  wrap>
    <v-flex xs12 class="headline">
      Welcome
    </v-flex>
    <v-flex xs12 class="title">
    Which policies are you interested in?
    </v-flex>
  </v-layout>
  <v-layout  wrap>
    <v-flex xs6 sm4 md3 class="pa-4" v-for="(link, index) in routes" :key="index">
      <v-card hover :href="link.path">
        <v-layout  align-center justify-center  wrap>
          <v-icon :href="link.path" class="huge" large>{{link.icon}}</v-icon>

        </v-layout>
        <v-card-title class="text-xs-center fixedHeight" justify-center primary-title>
          <v-layout   justify-center  wrap>
            <v-list>

            <h2 class="card-hover headline" :href="link.path">
              {{link.text}}


            </h2>
          </v-list>
          </v-layout>
        </v-card-title>
        <v-card-actions>

          <v-layout  wrap justify-center>
            <v-btn color="primary" flat :href="link.path" >go</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    routes: [
      {
        path:"/policies/terms",
        text:'Terms of Use'
      },
      {
        path:"/policies/refund",
        text:'Refund Policy'
      },

      {
        path:"/policies/privacy",
        text:'Privacy Policy'
      },
      {
        path:"/policies/cookies",
        text:'Cookie Policy'
      },
    ]

  })
}
</script>
<style lang="scss" scoped>
.adminHome{
  margin-bottom:5em;
}
.fixedHeight{
  height:4em;
}
.card-hover:hover{
  cursor:pointer;
  text-decoration: underline;
}
.huge{
  font-size:10em !important;
}
</style>
