<template>
<div id="">
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
        <div class="terms">
          <div class="">
            {{$t('terms.t000')}}
          </div>
          <br>
          <div class="">
            {{$t('terms.t001')}}
          </div>
          <br>
          <br>
        <div data-mercury="full" id="terms_page_content" >
          <h2>{{$t('terms.headline')}}<br></h2>
          <div><br></div>
          <div>
           {{$t('terms.t002')}}<span class="link" href='/policies/refund'>{{$t('terms.link001')}}</span>, <span class="link" href='/policies/privacy'>{{$t('terms.link002')}}</span>{{$t('terms.t003')}}
          </div>
          <div><br></div>
          <div>{{$t('terms.t004')}}</div>
          <div><br></div>
          <div class="headline">
            {{$t('terms.title0')}}
          </div>
          <div><br></div>
          <div>
            {{$t('terms.t10')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div><br></div>
          <div class="headline">1. {{$t('terms.title1')}}</div>
          <div><br></div>
          <div class="subheading">1.1 {{$t("terms.subtitle11")}}</div>

          <div>{{$t('terms.t110')}}</div>
          <div><br></div>
          <div>{{$t('terms.t111')}}</div>
          <div><br></div>
          <div class="">
            <ul>
              <li v-for="i in 24" :key="'li1_'+i">
                “<span class="bold">{{$t('terms.b11'+(i+1))}}</span>” {{$t('terms.t11'+(i+1))}}
              </li>
            </ul>
          </div>
          <br>
          <div>{{$t("terms.t1126")}}</div>
          <div><br></div>
          <div><br></div>
          <div><br></div>
          <div class="headline">2. {{$t('terms.title2')}}</div>
          <div><br></div>
          <div>
            2.1 {{$t('terms.t21')}}
          </div>
          <div><br></div>
          <div>
            2.2 {{$t('terms.t22')}}
          </div>
          <div><br></div>
          <div>
            2.3 {{$t('terms.t23')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">3. {{$t('terms.title3')}}</div>
          <div><br></div>
          <div>
            3.1 {{$t('terms.t31')}}
          </div>
          <div><br></div>
          <div>
            3.2 {{$t('terms.t32')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">4. {{$t('terms.title4')}}</div>
          <div><br></div>
          <div>
            4.1 {{$t('terms.t41')}}
          </div>
          <div><br></div>
          <div>
            4.2 {{$t('terms.t42')}}
          </div>
          <div><br></div>
          <div>
            4.3 {{$t('terms.t43')}}
          </div>
          <div><br></div>
          <div>
            4.4 {{$t('terms.t44')}}
          </div>
          <div><br></div>
          <div>
            4.5 {{$t('terms.t45')}}
          </div>
          <div><br></div>
          <div>
            4.6 {{$t('terms.t46')}}
          </div>
          <div><br></div>
          <div>
            4.7 {{$t('terms.t47')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">5. {{$t('terms.title5')}}</div>
          <div><br></div>
          <div v-for="i in 4" :key="'text1_'+i">

            <div>
              5.{{i}} {{$t('terms.t5'+i)}}
            </div>
            <div><br></div>
          </div>
          <div><br></div>
          <div class="headline">6. {{$t('terms.title6')}}</div>
          <div><br></div>
          <div>
            6.1 {{$t('terms.t61')}}
          </div>
          <div><br></div>
          <div>
            6.2 {{$t('terms.t62')}}
          </div>
          <ol type="a">
            <li v-for="i in 2" :key="'li2_'+i">{{$t('terms.l62'+i)}}</li>
          </ol>
          <div><br></div>
          <div>
            6.3 {{$t('terms.t63')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">7. {{$t('terms.title7')}}</div>
          <div><br></div>
          <div v-for="i in 5" :key="'text2_'+i">
            <div>7.{{i}} {{$t('terms.t7'+i)}}</div>
            <div><br></div>
          </div>
          <div><br></div>
          <div class="headline">8. {{$t('terms.title8')}}</div>
          <div><br></div>
          <div class="subheading">8.1 {{$t("terms.title81")}}</div>
          <div><br></div>

          <div>
            8.1.1 {{$t('terms.t811')}}
          </div>
          <div><br></div>
          <div>
            8.1.2 {{$t('terms.t812')}}
          </div>
          <div><br></div>
          <div class="subheading">8.2 {{$t("terms.title82")}}</div>
          <div><br></div>
          <div>
            8.2.1 {{$t('terms.t821')}}
          </div>
          <div><br></div>
          <div>
            8.2.2 {{$t('terms.t822')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">9. {{$t('terms.title9')}}</div>
          <div><br></div>
          <div>
            9.1 {{$t('terms.t91')}}
          </div>
          <div><br></div>
          <div>
            9.2 {{$t('terms.t92')}}
          </div>
          <div><br></div>
          <div>
            9.3 {{$t('terms.t93')}}
          </div>
          <div><br></div>
          <div>
            9.4 {{$t('terms.t94')}}
          </div>
          <ol type="a">
            <li v-for="i in 2" :key="'li94_'+i">{{$t('terms.l94'+i)}}</li>
          </ol>

          <div><br></div>
          <div class="subheading">9.5 {{$t("terms.title95")}}</div>
          <div><br></div>
          <div>
            9.5.1 {{$t('terms.t951')}}
          </div>
          <div><br></div>
          <div>
            9.5.2 {{$t('terms.t952')}}
          </div>
          <div><br></div>
          <div>
            9.5.3 {{$t('terms.t953')}}
          </div>

          <div><br></div>
          <div><br></div>
          <div class="headline">10. {{$t('terms.title10')}}</div>
          <div><br></div>
          <div v-for="i in 3" :key="'text3_'+i">
            <div>10.{{i}} {{$t('terms.t10'+i)}}</div>
            <div><br></div>
          </div>
          <div><br></div>
          <div class="headline">11. {{$t('terms.title11')}}</div>
          <div><br></div>
          <div>
            11.1 {{$t('terms.t111')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline" id="cancellation">12. {{$t('terms.title12')}}</div>
          <div><br></div>
          <div v-for="i in 3" :key="'text4_'+i">
            <div>12.{{i}} {{$t('terms.t12'+i)}}</div>
            <div><br></div>
          </div>
          <ol type="a" >
            <li v-for="i in 2" :key="'li5_'+i">{{$t('terms.l123'+i)}}</li>
            <ol type="i">
              <li v-for="i in 3" :key="'li6_'+i">{{$t('terms.ll1232'+i)}}</li>
            </ol>
            <div class="">
              {{$t('terms.l1232a')}}
            </div>
            <li>{{$t('terms.l1233')}}</li>
            <ol type="i">
              <li v-for="i in 3" :key="'li7_'+i">{{$t('terms.ll1233'+i)}}</li>
            </ol>
            <div class="">
              {{$t('terms.l1233a')}}
            </div>
            <li>{{$t('terms.l1234')}}</li>
            <ol type="i">
              <li v-for="i in 3" :key="'li8_'+i">{{$t('terms.ll1234'+i)}}</li>
            </ol>
            <div class="">
              {{$t('terms.l1234a')}}
            </div>
            <li>{{$t('terms.l1235')}}</li>
            <div>{{$t('terms.l1235a')}}</div>
          </ol>
          <br>
          <div v-for="i in 2" :key="'li9_'+i">
            <div>12.{{i+3}} {{$t('terms.t12'+(i+3))}}</div>
            <div><br></div>
          </div>
          <div><br></div>
          <div class="headline">13. {{$t('terms.title13')}}</div>
          <div><br></div>
          <div>13.1 {{$t('terms.t131')}}</div>
          <ol type="a">
            <li v-for="i in 2" :key="'li10_'+i">{{$t('terms.l131'+i)}}</li>
          </ol>
          <br>
          <div>13.2 {{$t('terms.t132')}}</div>
          <ol type="a">
            <li v-for="i in 3" :key="'li11_'+i">{{$t('terms.l132'+i)}}</li>
          </ol>
          <br>
          <div v-for="i in 2" class="" :key="'li12_'+i">
            <div>13.{{i+2}} {{$t('terms.t13'+(i+2))}}</div>
            <br>
          </div>
          <div>13.5 {{$t('terms.t135')}}</div>
          <ol type="a">
            <li v-for="i in 4" :key="'li13_'+i">{{$t('terms.l135'+i)}}</li>
          </ol>
          <br>
          <div>13.6 {{$t('terms.t136')}}</div>
          <ol type="a">
            <li v-for="i in 6" :key="'li14_'+i">{{$t('terms.l136'+i)}}</li>
            <ol type="i">
              <li v-for="i in 4" :key="'li15_'+i">{{$t('terms.ll1366'+i)}}</li>
            </ol>
            <li v-for="i in 2" :key="'li16_'+i">{{$t('terms.l136'+(i+6))}}</li>
            <ol type="i">
              <li v-for="i in 11" :key="'li17_'+i">{{$t('terms.ll1368'+i)}}</li>
            </ol>
          </ol>
          <br>
          <div v-for="i in 3" :key="'li18_'+i" class="">
            <div>13.{{i+6}} {{$t('terms.t13'+(i+6))}}</div>
            <br>
          </div>
          <div>13.10 {{$t('terms.t1310')}}</div>
          <ol type="a">
            <li v-for="i in 3" :key="'li19_'+i">{{$t('terms.l1310'+i)}}</li>
          </ol>
          <br>
          <br>
          <div class="headline">14. {{$t('terms.title14')}}</div>
          <div><br></div>
          <div v-for="i in 4" :key="'li20_'+i" class="">
            <div>14.{{i}} {{$t('terms.t14'+i)}}</div>
            <br>
          </div>
          <div class="">
              14.5 {{$t('terms.t145')}}
          </div>
          <ol type="a">
            <li v-for="i in 7" :key="'li21_'+i">{{$t('terms.l145'+i)}}</li>
          </ol>
          <br>
          <br>
          <div class="headline">15. {{$t('terms.title15')}}</div>
          <div><br></div>
          <div class="">
              15.1 {{$t('terms.t151')}}
          </div>
          <ol type="a">
            <li v-for="i in 4" :key="'li22_'+i">{{$t('terms.l151'+i)}}</li>
          </ol>
          <br>
          <br>
          <div class="headline">16. {{$t('terms.title16')}}</div>
          <div><br></div>
          <div v-for="i in 8" :key="'li23_'+i" class="">
            <div>16.{{i}} {{$t('terms.t16'+i)}}</div>
            <br>
          </div>
          <br>
          <div class="headline">17. {{$t('terms.title17')}}</div>
          <div><br></div>
          <div class="">
              17.1 {{$t('terms.t171')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">18. {{$t('terms.title18')}}</div>
          <div><br></div>
          <div class="">
              18.1 {{$t('terms.t181')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">19. {{$t('terms.title19')}}</div>
          <div><br></div>
          <div class="">
              19.1 {{$t('terms.t191')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">20. {{$t('terms.title20')}}</div>
          <div><br></div>
          <div v-for="i in 3" :key="'li24_'+i" class="">
            <div>20.{{i}} {{$t('terms.t20'+i)}}</div>
            <br>
          </div>
          <br>
          <div class="headline">21. {{$t('terms.title21')}}</div>
          <div><br></div>
          <div class="">
              21.1 {{$t('terms.t211')}}
          </div>
          <div><br></div>
          <div class="">
              21.2 {{$t('terms.t212')}}
          </div>
          <ol type="a">
            <li v-for="i in 6" :key="'li25_'+i">{{$t('terms.l212'+i)}}</li>
          </ol>
          <br>
          <div v-for="i in 6" :key="'li26_'+i" class="">
            <div>21.{{i+2}} {{$t('terms.t21'+(i+2))}}</div>
            <br>
          </div>
          <br>
          <div class="headline">22. {{$t('terms.title22')}}</div>
          <div><br></div>
          <div class="">
              22.1 {{$t('terms.t221')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">23. {{$t('terms.title23')}}</div>
          <div><br></div>
          <div v-for="i in 3" :key="'li27_'+i" class="">
            <div>23.{{i}} {{$t('terms.t23'+i)}}</div>
            <br>
          </div>
          <br>
          <div class="headline">24. {{$t('terms.title24')}}</div>
          <div><br></div>
          <div class="">
              24.1 {{$t('terms.t241')}}
          </div>
          <div><br></div>
          <div><br></div>
          <div class="headline">25. {{$t('terms.title25')}}</div>
          <div><br></div>
          <div v-for="i in 6" :key="'li28_'+i" class="">
            <div>25.{{i}} {{$t('terms.t25'+i)}}</div>
            <br>
          </div>
          <br>
          <div class="headline">26. {{$t('terms.title26')}}</div>
          <div><br></div>
          <div v-for="i in 7" :key="'li29_'+i" class="">
            <div>{{$t('terms.t26'+i)}}</div>
          </div>
          <br>


        </div>

      </div>
    </v-flex>
    </v-layout>

    </div>

</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  created() {
    //do something after creating vue instance

  }
}
</script>
<style lang="scss" scoped>
.terms {
    text-align: justify;
}
.bold{
  font-weight: bold;
}
.link{
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
}
ol.numberedOl{
  list-style-type: none;
}
</style>
