<template>
<div id="">
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
        <div class="terms">
          <h2 class="display-2">{{$t('cookies.cookies')}}</h2>
          <br>
          <div class="headline">
            1. {{$t('cookies.title1')}}
          </div>
          <br>
          <div class="">
            1.1 {{$t('cookies.t11')}}
          </div>
          <br>
          <div class="">
            1.2 {{$t('cookies.t12')}}
          </div>
          <br>
          <div class="">
            1.3 {{$t('cookies.t13')}}
          </div>
          <br>
          <div class="">
           {{$t('cookies.t131')}}
          </div>
          <br>
          <ul type="a">
            <li v-for="i in 4" :key="'li13_'+i">{{$t('cookies.l13'+i)}}</li>
          </ul>
          <br>
          <div class="">
            1.4 {{$t('cookies.t14')}}
          </div>
          <br>
          <div class="">
            1.5 {{$t('cookies.t15')}}
          </div>
          <br><div class="">
            1.6 {{$t('cookies.t16')}}
          </div>
          <br><div class="">
            1.7 {{$t('cookies.t17')}}
          </div>
          <br>
          <br>

        </div>
    </v-flex>
    </v-layout>

    </div>

</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.terms {
    text-align: justify;
}
.bold{
  font-weight: bold;
}
.link{
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
