<template>
<div id="">
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
        <div class="terms">
          <h2 class="display-2">{{$t('privacy.privacyPolicy')}}</h2>
          <br>
          <div class="headline">
            {{$t('privacy.heading')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.intro1')}}
          </div>
          <div class="">
            {{$t('privacy.intro2')}}
          </div>
          <div class="">
            {{$t('privacy.intro3')}}
          </div>
          <div class="">
            {{$t('privacy.intro4')}}
          </div>
          <br>
          <br>
          <div class="headline">
            1 {{$t('privacy.title1')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t11')}}
          </div>
          <div class="">
            {{$t('privacy.t12')}}
          </div>
          <div class="">
            {{$t('privacy.t13')}}
          </div>
          <br>
          <br>
          <div class="headline">
            2 {{$t('privacy.title2')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t21')}}
          </div>
          <ul>
            <li>{{$t('privacy.l201')}}</li>
            <li>{{$t('privacy.l202')}}</li>
            <li>{{$t('privacy.l203')}}</li>
            <li>{{$t('privacy.l204')}}</li>
            <li>{{$t('privacy.l205')}}</li>
            <li>{{$t('privacy.l206')}}</li>
            <li>{{$t('privacy.l207')}}</li>
            <li>{{$t('privacy.l208')}}</li>
            <li>{{$t('privacy.l209')}}</li>
          </ul>
          <br>
          <div class="title">
            2.1 {{$t('privacy.subtitle21')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t211')}}
          </div>
          <br>
          <div class="subheading">
            2.1.1 {{$t('privacy.subtitle211')}}
          </div>
          <div class="">
            {{$t('privacy.t2111')}}
          </div>
          <br>
          <div class="subheading">
            2.1.2 {{$t('privacy.subtitle212')}}
          </div>
          <div class="">
            {{$t('privacy.t2121')}}
          </div>
          <br>
          <div class="subheading">
            2.1.3 {{$t('privacy.subtitle213')}}
          </div>
          <div class="">
            {{$t('privacy.t2131')}}
          </div>
          <br>
          <div class="title">
            2.2 {{$t('privacy.subtitle22')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t220')}}
          </div>
          <br>
          <div class="subheading">
            2.2.1 {{$t('privacy.subtitle221')}}
          </div>
          <div class="">
            {{$t('privacy.t2211')}}
          </div>
          <br>
          <div class="subheading">
            2.2.2 {{$t('privacy.subtitle222')}}
          </div>
          <div class="">
            {{$t('privacy.t2221')}}
          </div>
          <br>
          <div class="subheading">
            2.2.3 {{$t('privacy.subtitle223')}}
          </div>
          <div class="">
            {{$t('privacy.t2231')}}
          </div>
          <div class="">
            {{$t('privacy.t2232')}}
          </div>
          <br>
          <div class="title">
            2.3 {{$t('privacy.subtitle23')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t230')}}
          </div>
          <br>
          <div class="subheading">
            2.3.1 {{$t('privacy.subtitle231')}}
          </div>
          <div class="">
            {{$t('privacy.t2311')}}
          </div>
          <br>
          <div class="subheading">
            2.3.2 {{$t('privacy.subtitle232')}}
          </div>
          <div class="">
            {{$t('privacy.t2321')}}
          </div>
          <br>
          <div class="subheading">
            2.3.3 {{$t('privacy.subtitle233')}}
          </div>
          <div class="">
            {{$t('privacy.t2331')}}
          </div>
          <div class="">
            {{$t('privacy.t2332')}}
          </div>
          <br>
          <div class="title">
            2.4 {{$t('privacy.subtitle24')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t240')}}
          </div>
          <br>
          <div class="subheading">
            2.4.1 {{$t('privacy.subtitle241')}}
          </div>
          <div class="">
            {{$t('privacy.t2411')}}
          </div>
          <br>
          <div class="subheading">
            2.4.2 {{$t('privacy.subtitle242')}}
          </div>
          <div class="">
            {{$t('privacy.t2421')}}
          </div>
          <br>
          <div class="subheading">
            2.4.3 {{$t('privacy.subtitle243')}}
          </div>
          <div class="">
            {{$t('privacy.t2431')}}
          </div>
          <div class="">
            {{$t('privacy.t2432')}}
          </div>
          <br>
          <div class="title">
            2.5 {{$t('privacy.subtitle25')}}
          </div>
          <br>
          <div class="subheading">
            2.5.1 {{$t('privacy.subtitle251')}}
          </div>
          <div class="">
            {{$t('privacy.t2511')}}
          </div>
          <div class="">
            {{$t('privacy.t2512')}}
          </div>
          <br>
          <div class="subheading">
            2.5.2 {{$t('privacy.subtitle252')}}
          </div>
          <div class="">
            {{$t('privacy.t2521')}}
          </div>
          <br>
          <div class="subheading">
            2.5.3 {{$t('privacy.subtitle253')}}
          </div>
          <div class="">
            {{$t('privacy.t2531')}}
          </div>
          <br>
          <div class="title">
            2.6 {{$t('privacy.subtitle26')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t260')}}
          </div>
          <br>
          <div class="subheading">
            2.6.1 {{$t('privacy.subtitle261')}}
          </div>
          <div class="">
            {{$t('privacy.t2611')}}
          </div>
          <div class="">
            {{$t('privacy.t2612')}}
          </div>
          <br>
          <div class="subheading">
            2.6.2 {{$t('privacy.subtitle262')}}
          </div>
          <div class="">
            {{$t('privacy.t2621')}}
          </div>
          <br>
          <div class="subheading">
            2.6.3 {{$t('privacy.subtitle263')}}
          </div>
          <div class="">
            {{$t('privacy.t2631')}}
          </div>
          <br>
          <div class="title">
            2.7 {{$t('privacy.subtitle27')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t270')}}
          </div>
          <br>
          <div class="title">
            2.8 {{$t('privacy.subtitle28')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t280')}}
          </div>
          <br>
          <div class="title">
            2.9 {{$t('privacy.subtitle29')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t290')}}
          </div>
          <br>
          <div class="subheading">
            2.9.1 {{$t('privacy.subtitle291')}}
          </div>
          <div class="">
            {{$t('privacy.t2911')}}
          </div>
          <br>
          <div class="subheading">
            2.9.2 {{$t('privacy.subtitle292')}}
          </div>
          <div class="">
            {{$t('privacy.t2921')}}
          </div>
          <br>
          <div class="subheading">
            2.9.3 {{$t('privacy.subtitle293')}}
          </div>
          <div class="">
            {{$t('privacy.t2931')}}
          </div>
          <br>
          <br>
          <div class="headline">
            3 {{$t('privacy.title3')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t30')}}
          </div>
          <ul>
            <li>{{$t('privacy.l301')}}</li>
            <li>{{$t('privacy.l302')}}</li>
            <li>{{$t('privacy.l303')}}</li>
            <li>{{$t('privacy.l304')}}</li>
            <li>{{$t('privacy.l305')}}</li>
          </ul>
          <div class="">
            {{$t('privacy.t31')}}
          </div>
          <br>
          <div class="title">
            3.1 {{$t('privacy.subtitle31')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t310')}}
          </div>
          <br>
          <div class="subheading">
            3.1.1 {{$t('privacy.subtitle311')}}
          </div>
          <div class="">
            {{$t('privacy.t3110')}}
          </div>
          <br>
          <div class="subheading">
            3.1.2 {{$t('privacy.subtitle312')}}
          </div>
          <div class="">
            {{$t('privacy.t3120')}}
          </div>
          <br>
          <div class="subheading">
            3.1.3 {{$t('privacy.subtitle313')}}
          </div>
          <div class="">
            {{$t('privacy.t3130')}}
          </div>
          <br>
          <div class="title">
            3.2 {{$t('privacy.subtitle32')}}
          </div>
          <br>
          <div class="subheading">
            3.2.1 {{$t('privacy.subtitle321')}}
          </div>
          <div class="">
            {{$t('privacy.t3210')}}
          </div>
          <br>
          <div class="subheading">
            3.2.2 {{$t('privacy.subtitle322')}}
          </div>
          <div class="">
            {{$t('privacy.t3220')}}
          </div>
          <br>
          <div class="subheading">
            3.2.3 {{$t('privacy.subtitle323')}}
          </div>
          <div class="">
            {{$t('privacy.t3230')}}
          </div>
          <br>
          <div class="title">
            3.3 {{$t('privacy.subtitle33')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t330')}}
          </div>
          <br>
          <div class="subheading">
            3.3.1 {{$t('privacy.subtitle331')}}
          </div>
          <div class="">
            {{$t('privacy.t3310')}}
          </div>
          <br>
          <div class="subheading">
            3.3.2 {{$t('privacy.subtitle332')}}
          </div>
          <div class="">
            {{$t('privacy.t3320')}}
          </div>
          <br>
          <div class="subheading">
            3.3.3 {{$t('privacy.subtitle333')}}
          </div>
          <div class="">
            {{$t('privacy.t3330')}}
          </div>
          <br>
          <div class="title">
            3.4 {{$t('privacy.subtitle34')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t340')}}
          </div>
          <br>
          <div class="title">
            3.3 {{$t('privacy.subtitle35')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t350')}}
          </div>
          <br>
          <br>
          <div class="headline">
            4 {{$t('privacy.title4')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t40')}}
          </div>
          <ul>
            <li>{{$t('privacy.l401')}}</li>
            <li>{{$t('privacy.l402')}}</li>
            <li>{{$t('privacy.l403')}}</li>
            <li>{{$t('privacy.l404')}}</li>
            <li>{{$t('privacy.l405')}}</li>
          </ul>
          <br>
          <div class="title">
            4.1 {{$t('privacy.subtitle41')}}
          </div>
          <br>
          <div class="subheading">
            4.1.1 {{$t('privacy.subtitle411')}}
          </div>
          <div class="">
            {{$t('privacy.t4110')}}
          </div>
          <br>
          <div class="subheading">
            4.1.2 {{$t('privacy.subtitle412')}}
          </div>
          <div class="">
            {{$t('privacy.t4120')}}
          </div>
          <br>
          <div class="subheading">
            4.1.3 {{$t('privacy.subtitle413')}}
          </div>
          <div class="">
            {{$t('privacy.t4130')}}
          </div>
          <br>
          <div class="title">
            4.2 {{$t('privacy.subtitle42')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t420')}}
          </div>
          <br>
          <div class="subheading">
            4.2.1 {{$t('privacy.subtitle421')}}
          </div>
          <div class="">
            {{$t('privacy.t4210')}}
          </div>
          <br>
          <div class="subheading">
            4.2.2 {{$t('privacy.subtitle422')}}
          </div>
          <div class="">
            {{$t('privacy.t4220')}}
          </div>
          <br>
          <div class="subheading">
            4.2.3 {{$t('privacy.subtitle423')}}
          </div>
          <div class="">
            {{$t('privacy.t4230')}}
          </div>
          <br>
          <div class="title">
            4.3 {{$t('privacy.subtitle43')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t430')}}
          </div>
          <br>
          <div class="subheading">
            4.3.1 {{$t('privacy.subtitle431')}}
          </div>
          <div class="">
            {{$t('privacy.t4310')}}
          </div>
          <br>
          <div class="subheading">
            4.3.2 {{$t('privacy.subtitle432')}}
          </div>
          <div class="">
            {{$t('privacy.t4320')}}
          </div>
          <div class="">
            {{$t('privacy.t4321')}}
          </div>
          <br>
          <div class="subheading">
            4.3.3 {{$t('privacy.subtitle433')}}
          </div>
          <div class="">
            {{$t('privacy.t4330')}}
          </div>
          <br>
          <div class="title">
            4.4 {{$t('privacy.subtitle44')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t440')}}
          </div>
          <div class="">
            {{$t('privacy.t441')}}
          </div>
          <br>
          <div class="subheading">
            4.4.1 {{$t('privacy.subtitle441')}}
          </div>
          <div class="">
            {{$t('privacy.t4410')}}
          </div>
          <br>
          <div class="subheading">
            4.4.2 {{$t('privacy.subtitle442')}}
          </div>
          <div class="">
            {{$t('privacy.t4420')}}
          </div>
          <div class="">
            {{$t('privacy.t4421')}}
          </div>
          <br>
          <div class="subheading">
            4.4.3 {{$t('privacy.subtitle443')}}
          </div>
          <div class="">
            {{$t('privacy.t4430')}}
          </div>
          <br>
          <div class="title">
            4.5 {{$t('privacy.subtitle45')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t450')}}
          </div>
          <div class="">
            {{$t('privacy.t451')}}
          </div>
          <div class="">
            {{$t('privacy.t452')}}
          </div>
          <br>
          <div class="subheading">
            4.5.1 {{$t('privacy.subtitle451')}}
          </div>
          <div class="">
            {{$t('privacy.t4510')}}
          </div>
          <br>
          <div class="subheading">
            4.5.2 {{$t('privacy.subtitle452')}}
          </div>
          <div class="">
            {{$t('privacy.t4520')}}
          </div>
          <div class="">
            {{$t('privacy.t4521')}}
          </div>
          <br>
          <div class="subheading">
            4.5.3 {{$t('privacy.subtitle453')}}
          </div>
          <div class="">
            {{$t('privacy.t4530')}}
          </div>
          <br>
          <br>
          <div class="headline">
            5 {{$t('privacy.title5')}}
          </div>

          <br>

          <div class="title">
            5.1 {{$t('privacy.subtitle51')}}
          </div>
          <br>
          <div class="subheading">

            5.1.1 {{$t('privacy.subtitle511')}}
          </div>
          <div class="">
            {{$t('privacy.t5110')}}
          </div>
          <div class="">
            {{$t('privacy.t5111')}}
          </div>
          <br>

          <div class="subheading">
            5.1.2 {{$t('privacy.subtitle512')}}
          </div>
          <div class="">
            {{$t('privacy.t5120')}}
          </div>
          <br>
          <div class="subheading">
            5.1.3 {{$t('privacy.subtitle513')}}
          </div>

          <div class="">
            {{$t('privacy.t5130')}}
          </div>
          <br>
          <br>
          <div class="headline">
            6 {{$t('privacy.title6')}}
          </div>
          <br>

          <div class="">
            {{$t('privacy.t60')}}
          </div>
          <ul>
            <li>{{$t('privacy.l601')}}</li>
            <li>{{$t('privacy.l602')}}</li>
            <li>{{$t('privacy.l603')}}</li>
          </ul>
          <div class="">
            {{$t('privacy.t61')}}
          </div>
          <br>

          <div class="title">
            6.1 {{$t('privacy.subtitle61')}}
          </div>
          <br>
          <div class="subheading">
            6.1.1 {{$t('privacy.subtitle611')}}
          </div>
          <div class="">
            {{$t('privacy.t6110')}}
          </div>
          <br>
          <div class="subheading">
            6.1.2 {{$t('privacy.subtitle612')}}
          </div>
          <div class="">
            {{$t('privacy.t6120')}}
          </div>
          <br>
          <div class="subheading">
            6.1.3 {{$t('privacy.subtitle613')}}
          </div>
          <div class="">
            {{$t('privacy.t6130')}}
          </div>
          <div class="">
            {{$t('privacy.t6131')}}
          </div>

          <br>
          <div class="title">
            6.2 {{$t('privacy.subtitle62')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t620')}}
          </div>
          <br>
          <div class="subheading">
            6.2.1 {{$t('privacy.subtitle621')}}
          </div>
          <div class="">
            {{$t('privacy.t6210')}}
          </div>
          <br>
          <div class="subheading">
            6.2.2 {{$t('privacy.subtitle622')}}
          </div>
          <div class="">
            {{$t('privacy.t6220')}}
          </div>
          <div class="">
            {{$t('privacy.t6221')}}
          </div>
          <br>
          <div class="subheading">
            6.2.3 {{$t('privacy.subtitle623')}}
          </div>
          <div class="">
            {{$t('privacy.t6230')}}
          </div>
          <div class="">
            {{$t('privacy.t6231')}}
          </div>
          <br>
          <div class="title">

            6.3 {{$t('privacy.subtitle63')}}
          </div>
          <br>
          <div class="subheading">
            6.3.1 {{$t('privacy.subtitle631')}}
          </div>
          <div class="">
            {{$t('privacy.t6310')}}
          </div>
          <br>
          <div class="subheading">
            6.3.2 {{$t('privacy.subtitle632')}}
          </div>
          <div class="">
            {{$t('privacy.t6320')}}
          </div>
          <div class="">
            {{$t('privacy.t6321')}}
          </div>
          <br>
          <div class="subheading">
            6.3.3 {{$t('privacy.subtitle633')}}
          </div>
          <div class="">
            {{$t('privacy.t6330')}}
          </div>
          <br>
          <br>
          <div class="headline">
            7 {{$t('privacy.title7')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t70')}}
          </div>
          <ul>
            <li>{{$t('privacy.l701')}}</li>
            <li>{{$t('privacy.l702')}}</li>
            <li>{{$t('privacy.l703')}}</li>
            <li>{{$t('privacy.l704')}}</li>
            <li>{{$t('privacy.l705')}}</li>
            <li>{{$t('privacy.l706')}}</li>
            <li>{{$t('privacy.l707')}}</li>
            <li>{{$t('privacy.l708')}}</li>
            <li>{{$t('privacy.l709')}}</li>
          </ul>
          <div class="">
            {{$t('privacy.t71')}}
          </div>
          <br>
          <div class="title">
            7.1 {{$t('privacy.subtitle71')}}
          </div>
          <br>
          <div class="subheading">
            7.1.1 {{$t('privacy.subtitle711')}}
          </div>
          <div class="">
            {{$t('privacy.t7110')}}
          </div>
          <br>
          <div class="subheading">
            7.1.2 {{$t('privacy.subtitle712')}}
          </div>
          <div class="">
            {{$t('privacy.t7120')}}
          </div>
          <br>
          <div class="subheading">
            7.1.3 {{$t('privacy.subtitle713')}}
          </div>
          <div class="">
            {{$t('privacy.t7130')}}
          </div>
          <br>
          <div class="title">
            7.2 {{$t('privacy.subtitle72')}}
          </div>
          <br>
          <div class="subheading">
            7.2.1 {{$t('privacy.subtitle721')}}
          </div>
          <div class="">
            {{$t('privacy.t7210')}}
          </div>
          <br>
          <div class="subheading">
            7.2.2 {{$t('privacy.subtitle722')}}
          </div>
          <div class="">
            {{$t('privacy.t7220')}}
          </div>
          <br>
          <div class="subheading">
            7.2.3 {{$t('privacy.subtitle723')}}
          </div>
          <div class="">
            {{$t('privacy.t7230')}}
          </div>
          <div class="">
            {{$t('privacy.t7231')}}
          </div>
          <br>
          <div class="title">
            7.3 {{$t('privacy.subtitle73')}}
          </div>
          <br>
          <div class="subheading">
            7.3.1 {{$t('privacy.subtitle731')}}
          </div>
          <div class="">
            {{$t('privacy.t7310')}}
          </div>
          <br>
          <div class="subheading">
            7.3.2 {{$t('privacy.subtitle732')}}
          </div>
          <div class="">
            {{$t('privacy.t7320')}}
          </div>
          <br>
          <div class="subheading">
            7.3.3 {{$t('privacy.subtitle733')}}
          </div>
          <div class="">
            {{$t('privacy.t7330')}}
          </div>
          <br>
          <div class="title">
            7.4 {{$t('privacy.subtitle74')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t740')}}
          </div>
          <br>
          <div class="subheading">
            7.4.1 {{$t('privacy.subtitle741')}}
          </div>
          <div class="">
            {{$t('privacy.t7410')}}
          </div>
          <br>
          <div class="subheading">
            7.4.2 {{$t('privacy.subtitle742')}}
          </div>
          <div class="">
            {{$t('privacy.t7420')}}
          </div>
          <br>
          <div class="subheading">
            7.4.3 {{$t('privacy.subtitle743')}}
          </div>
          <div class="">
            {{$t('privacy.t7430')}}
          </div>
          <br>
          <div class="title">
            7.5 {{$t('privacy.subtitle75')}}
          </div>
          <br>
          <div class="subheading">
            7.5.1 {{$t('privacy.subtitle751')}}
          </div>
          <div class="">
            {{$t('privacy.t7510')}}
          </div>
          <br>
          <div class="subheading">
            7.5.2 {{$t('privacy.subtitle752')}}
          </div>
          <div class="">
            {{$t('privacy.t7520')}}
          </div>
          <br>
          <div class="subheading">
            7.5.3 {{$t('privacy.subtitle753')}}
          </div>
          <div class="">
            {{$t('privacy.t7530')}}
          </div>
          <div class="">
            {{$t('privacy.t7531')}}
          </div>
          <br>
          <div class="title">
            7.6 {{$t('privacy.subtitle76')}}
          </div>
          <br>
          <div class="subheading">
            7.6.1 {{$t('privacy.subtitle761')}}
          </div>
          <div class="">
            {{$t('privacy.t7610')}}
          </div>
          <br>
          <div class="subheading">
            7.6.2 {{$t('privacy.subtitle762')}}
          </div>
          <div class="">
            {{$t('privacy.t7620')}}
          </div>
          <br>
          <div class="subheading">
            7.6.3 {{$t('privacy.subtitle763')}}
          </div>
          <div class="">
            {{$t('privacy.t7630')}}
          </div>
          <br>
          <div class="title">
            7.7 {{$t('privacy.subtitle77')}}
          </div>
          <br>
          <div class="subheading">
            7.7.1 {{$t('privacy.subtitle771')}}
          </div>
          <div class="">
            {{$t('privacy.t7710')}}
          </div>
          <br>
          <div class="subheading">
            7.7.2 {{$t('privacy.subtitle772')}}
          </div>
          <div class="">
            {{$t('privacy.t7720')}}
          </div>
          <div class="">
            {{$t('privacy.t7721')}}
          </div>
          <br>
          <div class="subheading">
            7.7.3 {{$t('privacy.subtitle773')}}
          </div>
          <div class="">
            {{$t('privacy.t7730')}}
          </div>
          <br>
          <div class="title">
            2.8 {{$t('privacy.subtitle78')}}
          </div>
          <br>
          <div class="subheading">
            7.8.1 {{$t('privacy.subtitle781')}}
          </div>
          <div class="">
            {{$t('privacy.t7810')}}
          </div>
          <br>
          <div class="subheading">
            7.8.2 {{$t('privacy.subtitle782')}}
          </div>
          <div class="">
            {{$t('privacy.t7820')}}
          </div>
          <br>
          <div class="subheading">
            7.8.3 {{$t('privacy.subtitle783')}}
          </div>
          <div class="">
            {{$t('privacy.t7830')}}
          </div>
          <br>
          <br>
          <div class="headline">
            8 {{$t('privacy.title8')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t80')}}
          </div>
          <div class="">
            {{$t('privacy.t81')}}
          </div>
          <div class="">
            {{$t('privacy.t82')}}
          </div>
          <div class="">
            {{$t('privacy.t83')}}
          </div>
          <div class="">
            {{$t('privacy.t84')}}
          </div>
          <div class="">
            {{$t('privacy.t85')}}
          </div>
          <div class="">
            {{$t('privacy.t86')}}
          </div>
          <div class="">
            {{$t('privacy.t87')}}
          </div>
          <div class="">
            {{$t('privacy.t88')}}
          </div>
          <br>
          <br>
          <div class="headline">
            9 {{$t('privacy.title9')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t90')}}
          </div>
          <br>
          <div class="title">
            9.1 {{$t('privacy.subtitle91')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t910')}}
          </div>
          <br>
          <div class="title">
            9.2 {{$t('privacy.subtitle92')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t920')}}
          </div>
          <br>
          <div class="title">
            9.3 {{$t('privacy.subtitle93')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t930')}}
          </div>
          <br>
          <div class="title">
            9.4 {{$t('privacy.subtitle94')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t940')}}
          </div>
          <br>
          <div class="title">
            9.5 {{$t('privacy.subtitle95')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t950')}}
          </div>
          <br>
          <div class="title">
            9.6 {{$t('privacy.subtitle96')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t960')}}
          </div>
          <br>
          <div class="title">
            9.7 {{$t('privacy.subtitle97')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t970')}}
          </div>
          <div class="">
            {{$t('privacy.t971')}}
          </div>
          <div class="">
            {{$t('privacy.t972')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t973')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t974')}}
          </div>
          <br>
          <br>
          <div class="headline">
            10 {{$t('privacy.title10')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t101')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t102')}}
          </div>
          <br>
          <div class="">
            {{$t('privacy.t103')}}
          </div>
          <br>

        </div>
    </v-flex>
    </v-layout>

    </div>

</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.terms {
    text-align: justify;
}
.bold{
  font-weight: bold;
}
.link{
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
