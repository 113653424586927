<template>
  <div class="">
<div class="search-row">
  <Navbar navbarClass="transparent"></Navbar>
    <v-layout  wrap justify-center>
      <h1 class="display-3 blog-title">
        Policies
      </h1>
    </v-layout>

  </div>
    <v-toolbar dense dark color="primary">
      <v-toolbar-side-icon class="hidden-md-and-up" @click="drawer=!drawer"></v-toolbar-side-icon>

      <v-spacer></v-spacer>
      <v-toolbar-items>
          <v-list-tile dark class="hover" v-for="(item,index) in linkList" :key="index" :href="item.path">
            <v-list-tile-title>{{item.text}}</v-list-tile-title>
          </v-list-tile>

      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer  absolute temporary v-model="drawer">
      <v-toolbar color="primary" dark flat >
        <v-list>
          <v-list-tile>
            <v-list-tile-title class="title">
              Terms
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        <v-list-tile v-for="(item,index) in linkList" :key="index" :href="item.path">
          <v-list-tile-title>{{item.text}}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-container grid-list-xs,sm,md,lg,xl>

      <router-view class=""></router-view>
    </v-container>
</div>
</template>

<script>
export default {
  name: 'PersistentFull',
  data: () => ({
    drawer:false,
    linkList:[
      {
        path:"/policies/terms",
        text:'Terms of Use'
      },
      {
        path:"/policies/refund",
        text:'Refund Policy'
      },
      {
        path:"/policies/privacy",
        text:'Privacy Policy'
      },
      {
        path:"/policies/cookies",
        text:'Cookie Policy'
      },


    ]

  }),
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    }
  },
  computed: {
    smallScreen: function() {

      if ( screen.width <= 600) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.toolbar-text{
  color:#FFFFFF !important;
}
.hover:hover{
  background-color:rgba(255,255,255,.2);

}
.search-row {
    background: rgba(255,255,255,0);
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg") center/cover no-repeat;
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}

// Demo purposes only
.md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
    float:left;
}
</style>
