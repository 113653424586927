<template>
<div id="">
    <v-layout  wrap justify-center class="terms">
      <v-flex md8 sm9 xs10>
        <div data-mercury="full" id="terms_page_content">
          <h2>{{$t('refund.title')}}<br></h2>
          <div><br></div>
          <div class="headline">1.1 {{$t('refund.subtitle11')}}</div>
          <div><br></div>
          <ol type="i">
            <div v-for="i in 3" class="" :key="'text1_'+i">
              <li>{{$t('refund.t11'+i)}}</li>
              <br>
            </div>
          </ol>
          <div><br></div>
          <div><br></div>
          <div class="headline">1.2 {{$t('refund.subtitle12')}}</div>
          <div><br></div>
          <ol type="i">
            <div v-for="i in 9" class=""  :key="'text2_'+i">
              <li>{{$t('refund.t12'+i)}}</li>
              <br>
            </div>
          </ol>
          <div><br></div>
          <div><br></div>
          <div class="headline">1.3 {{$t('refund.subtitle13')}}</div>
          <div><br></div>
          <ol type="i">
              <div v-for="i in 4" class=""  :key="'text3_'+i">
              <li>{{$t('refund.t13'+i)}}</li>
              <br>
            </div>
          </ol>
          <div><br></div>
          <div><br></div>
        </div>

    </v-flex>
    </v-layout>

    </div>

</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.terms {
    text-align: justify;
}
.bold{
  font-weight: bold;
}
.link{
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
